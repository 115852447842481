.center {
	display: block;
	margin-left: auto;
	margin-right: auto;
	min-height: 200px;
	max-height: 350px;
}

.feedBackBtn {
	padding: 8px 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 6px;
	flex-basis: 30%;
}

.content {
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 3;
}

.feedBackBtn h4 {
	font-size: 16px;
	margin: 0;
	margin-left: 5px;
}

.card1 {
	border-radius: 20px 20px 0px 0 !important;
	background: black !important;
	border-width: 0 !important;
}

.card2 {
	border-radius: 0 0 20px 20px !important;
	background: white !important;
	border-width: 0 !important;
}

.card3 {
	border-radius: 20px 20px 20px 20px !important;
	background: white !important;
	border-width: 0 !important;
}

.description {
	color: #000;
	font-family: Hind;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}

.user-name {
	color: #000;
	font-family: Hind;
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	margin-bottom: 0;
}

.user-city {
	color: #000;
	font-family: Hind;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
}

.reViewsTxt {
	color: #000;
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	text-transform: capitalize;
}
