.Quizbtn {
	position: relative;
	display: flex;
	overflow: hidden;
	justify-content: center;
	align-items: center;
	width: 100%;
	border-radius: 58px;
	padding: 10px;
}

.btnInner {
	display: flex;
	position: absolute;
	top: 45%;
	left: 50%;
}

.Quizbtn h4 {
	margin: 0;
	padding: 0;
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
	line-height: 26px;
	text-align: center;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
