.container {
	height: 100vh;
	width: 100%;
	padding: 0 !important;
	background: linear-gradient(180deg, #040025 60%, #040025 0%, #2c156d 100%);
}
.headerLinearGradient {
	height: 170px;
	width: 100%;
	position: absolute;
	z-index: 0;
}
.header {
	width: 100%;
	border-bottom-left-radius: 16px;
	border-bottom-right-radius: 16px;
	display: flex;
	flex-direction: column;
	align-items: center;
}
.header1 {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	position: relative;
	z-index: 1;
}

.score {
	display: flex;
	padding: 8px 12px;
	border-radius: 5px;
	justify-content: space-between;
	align-items: center;
	border: 0.3px solid #fff;
	background-color: #001F74;
	margin-top: 12%;
}

.score h4 {
	text-transform: capitalize;
	color: #8b8b8b;
	text-align: right;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	margin: 0;
}

.score h2 {
	color: #fff;
	text-align: right;
	font-size: 20.651px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	margin: 0;
	margin-right: 5px;
}

.headerPoints {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: 2rem;
}

.quizDiv {
	width: 100%;
	padding: 20px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: relative;
	z-index: 10;
	padding-top: 10%;
}

.quizMainDiv {
	position: relative;
	z-index: 10;
}

.questionCard {
	min-height: 264px;
	height: 30%;
	width: 100%;
	margin-top: 3%;
	border-radius: 16px;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 10px 15px;
	background: linear-gradient(180deg, #0B2B80 0%, #1A2C6E 100%);
	/* border: 0.2px solid #aeaeae; */
	box-shadow: 0px 4px 36px 0px rgba(0, 0, 0, 0.28);
}

.questionCard h4 {
	color: white;
	text-align: center;
	font-feature-settings: 'clig' off, 'liga' off;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 32px; /* 200% */
	margin-bottom: 15px;
	position: relative;
}
.questionCard h2 {
	color: white;
	text-align: center;
	font-feature-settings: 'clig' off, 'liga' off;
	font-size: 20px;
	font-style: normal;
	font-weight: 700;
	line-height: 32px; /* 133.333% */
	/* margin-top: 15px; */
}

.option {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.activeQuestion {
	border-radius: 8px;
	height: 5px;
	background: #fff;
}

.activeQuestionDiv {
	padding: 20px;
	padding-top: 10%;
	display: flex;
	width: 100%;
	justify-content: space-between;
	position: relative;
	z-index: 100;
}

.points {
	display: flex;
	border-radius: 30px;
	padding: 7px 10px;
	background-color: #1b0056;
	justify-content: center;
	align-items: center;
	margin-top: 3%;
}

.points h2 {
	color: #fff;
	text-align: right;
	font-size: 26px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	margin: 0;
	margin-left: 10px;
}

.backDrop {
	width: 100%;
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: rgba(0, 0, 0, 0.7);
	position: absolute;
	z-index: 100;
}

.timer {
	width: 58px;
	height: 58px;
}

.completed {
	display: flex;
	height: 100vh;
	overflow: hidden;
	color: white;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
	background-color: #08032B;
	padding: 16px;
	padding-top: 10%;
}

.clapDiv {
	display: flex;
	align-items: center;
	justify-content: center;
}

.clapDiv h4 {
	color: #fff;
	font-feature-settings: 'clig' off, 'liga' off;
	font-size: 39.062px;
	font-style: normal;
	font-weight: 600;
	line-height: 34.093px; /* 87.28% */
	letter-spacing: 0.682px;
	margin: 0;
	margin-left: 10px;
}
.result {
	display: flex;
	justify-content: space-around;
	align-items: center;
	width: 100%;
	padding-top: 24px;
}

.result h4 {
	color: #fff;
	text-align: right;
	font-size: 21.504px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	margin: 0;
}

.upperDiv {
	height: 50vh;
	display: flex;
	width: 120%;
	flex-direction: column;
	border-bottom-left-radius: 40%;
	border-bottom-right-radius: 40%;
	background-color: #17004a;
	border-bottom-width: 10px;
	border-bottom-color: #fedb41;
	padding: 0 10%;
	padding-top: 15%;
	position: relative;
}

.btnDiv {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	align-self: flex-end;
	padding: 20px;
	bottom: 0;
}
.quizEndbtn {
	flex-basis: 45%;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 10px;
	padding: 20px;
	text-decoration: none;
}

.btnText {
	text-align: center;
	font-feature-settings: 'clig' off, 'liga' off;
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 16px; /* 100% */
	letter-spacing: 0.4px;
	margin: 0;
}

.leaderBoardpoint {
	color: #fff;
	margin: 0;
	text-align: right;
	font-size: 19.058px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}

.completedCard {
	width: 100%;
	border-radius: 18px;
	border: 1px solid #7b7b7b;
	background: radial-gradient(50% 50% at 50% 50%, #2d056e 0%, #28057b 100%);
	display: flex;
	flex-direction: column;
	padding: 26px;
	padding-top: 20px;
	padding-bottom: 6%;
	justify-content: space-between;
	background-color: red;
	align-items: center;
	height: 70%;
	margin-bottom: 5%;
}

.score h4 {
	color: #8b8b8b;
	text-align: right;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	text-transform: capitalize;
}

.score h2 {
	color: #fff;
	text-align: right;
	font-size: 18px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	margin: 0;
	margin-right: 5px;
}

.headerPoints {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: 2rem;
}

.completedCard h3 {
	color: #fff;
	font-feature-settings: 'clig' off, 'liga' off;
	font-size: 22px;
	text-align: center;
	font-style: normal;
	font-weight: 500;
	line-height: 34.912px; /* 193.956% */
	letter-spacing: 0.698px;
}
