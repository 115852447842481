.product-card {
  width: 344px;
  /* border-radius: 4px; */
  /* box-shadow: 0px 0px 0px 0.1px; */
  border-bottom: 1px solid #ededed;
  /* padding:1rem; */
  margin-bottom: 2rem;
  padding-left: 2%;
  padding-right: 2%;
}

.inner-section {
  display: flex;
}

.product-img {
  width: 29%;
  height: 48%;
  object-fit: fill;
  margin: 0.7rem;
  align-self: center;
}

.product-desc {
  text-align: left;
  width: 65%;
  margin-right: 0.7rem;
  /* border-style:solid;
  border-width: 4px;
  border-color: black; */
}

.product-head {
  /* font-weight: 600; */
  font-size: 1em;
  /* border-style: dotted; */
  margin-right: 12em;
  padding-top: 1rem;
  letter-spacing: 8%;
}


.product-head-detail {
  font-weight: 600;
  font-size: 1rem;
  /* border-style: dotted; */
  /* margin-right: 0.1rem; */
  padding-top: 1.8rem;
  line-height: 1.25rem;
}

.product-price {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.187rem !important;
  color: #707070;
}

.bold-text {
  font-weight: 400;
  font-size: 0.875rem;
  margin-left: 0.2rem;
  color: black;
}
.price {
  font-size: 1rem;
  color: black;
  font-weight: 600;
  margin: 0rem 0.2rem;
}

.product span {
  color: #707070;
  font-weight: 400;
  font-size: 0.75rem;
}

.hind {
  font-family: "Hind", sans-serif;
  font-size: 0.75rem;
}

.demand {
  margin-right: 0.2;
}

.munafa {
  margin-left: 0.5rem;
}

.arrow {
  width: 12px;
  height: 12px;
  color: #d45339;
  background-color: #d45339;
  padding: 0.1rem;
  border-radius: 1rem;
}
.line {
  width: 60px;
  height: 12px;
  color: #d45339;
  background-color: #d45339;
  padding: 0.1rem;
  border-radius: 1rem;
}

.product-information {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border: 1px solid #d45339;
  border-radius: 0.3rem;
  padding: 0.3em 0.5em;
  margin: 1rem 0rem;
  text-decoration: none;
}

.btn-text {
  font-family: "Hind", sans-serif;
  font-weight: 600;
  color: #d45339;
  font-size: 0.875rem;
  padding-right: 0.5rem;
  margin: 0rem;
  text-decoration: none;
}
.icon-star {
}
