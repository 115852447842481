.err-shake {
	/* animation: horizontal-shaking 0.35s; */
	color: red;
	align-self: center;
	font-weight: bold;
	font-size: large;
	margin-bottom: 1rem;
}
.react-modal-sheet-container {
	/* height: 18rem!important; */
}

.custom-sheet-container {
	height: 45% !important;
}
.animation {
	animation: horizontal-shaking 0.35s;
}

@keyframes horizontal-shaking {
	0% {
		transform: translateX(0);
	}

	25% {
		transform: translateX(10px);
	}

	50% {
		transform: translateX(-10px);
	}

	75% {
		transform: translateX(10px);
	}

	100% {
		transform: translateX(0);
	}
}

.poststatuscardheader {
	border-radius: 0.775rem 0.775rem 0rem 0rem !important;
}

.poststatuscard {
	border-radius: 0.775rem !important;
}

.xlikescard {
	border-radius: 20px;
	background-color: white;
	border: 0.17px solid;
	font-size: 13.46px;
	font-weight: 500;
	/* border-color: rgba(5, 131, 243, 1) !important; */
}

.trendingBox {
	display: flex;
	justify-content: space-between;
	/* box-shadow: 0px 3.3657288551330566px 3.3657288551330566px 0px rgba(0, 0, 0, 0.25); */
	border-radius: 10px;
	border-width: 2px;
	border-color: white;
	/* width: 48%; */
}

.trendingBox2 {
	border-radius: 10px;
	width: 48%;
}

.noofposts {
	position: relative;
	bottom: 94px;
	z-index: 100;
	color: white;
	font-size: 12px;
	font-weight: 500;
	display: flex;
	justify-content: end;
}
.noofposts2 {
	position: relative;
	bottom: 35px;
	z-index: 100;
	color: white;
	font-size: 12px;
	font-weight: 500;
	display: flex;
	justify-content: end;
}
