.genericImage {
	width: 100%;
}
.thankYouDiv {
	display: flex;
    flex-direction: column;
	justify-content: center;
	align-items: center;
    padding: 2rem;
}

.thankYouDiv h4 {
	font-size: 23px;
    text-align: center;
}
.thankYouDiv h3 {
	font-size: 24px;
	font-weight: bold;
}